
<template>
  <div class="login_box2" @keyup.enter="submit()">
    <!-- 登录表单区域 -->
    <h2 class="bic">用户登录</h2>
    <el-form size="mini" :model="userForm" :rules="rules" ref="userForm">
      <!-- 用户名 -->
      <el-form-item prop="username">
        <el-input
          placeholder="用户名"
          v-model="userForm.username"
          autocomplete="on"
          prefix-icon="el-icon-user"
        ></el-input>
      </el-form-item>
      <!-- 密码 -->
      <el-form-item prop="password">
        <el-input
          placeholder="密码"
          show-password
          v-model="userForm.password"
          prefix-icon="el-icon-lock"
        ></el-input>
      </el-form-item>
      <!-- 请选择账号类型 -->
      <!-- <el-form-item prop="ServiceProviderType" > 
        <el-select
          v-model="userForm.ServiceProviderType"
          style="width: 100%"
          placeholder="请选择账号类型"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <!-- 验证码 -->
      <el-form-item prop="verifycode">
        <el-input
          class="code"
          v-model="userForm.verifycode"
          placeholder="验证码"
          autocomplete="on"
          prefix-icon="el-icon-lock"
        ></el-input>
        <a>
          <img
            ref="code"
            @click="getcode"
            :src="'data:image/png;base64,' + img"
          />
        </a>
      </el-form-item>
      <!-- 记住我 -->

      <!-- 登录按钮 -->
      <el-form-item>
        <el-button
          type="primary"
          style="margin-top: 5px"
          @click="submitForm('userForm')"
          :loading="loading"
          >登　录</el-button
        >
      </el-form-item>
      <el-form-item>
        <div style="text-align: right">
          <el-checkbox
            label="记住密码"
            v-model="checked"
            class="rememberMe"
          ></el-checkbox>
          <el-link
            type="primary"
            :underline="false"
            style="margin: 0 10px"
            slot="reference"
            @click="reference"
            >忘记密码？</el-link
          >
        </div>
      </el-form-item>
    </el-form>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="95%"
      @close="close"
    >
      <div>
        <!-- oninput="this.value = this.value.replace(/[^0-9]/g, '');" -->
        <el-input
          v-model="dateForm.phone"
          placeholder="手机号"
          :disabled="codemsg"
          autocomplete="on"
          maxlength="11"
          prefix-icon="el-icon-phone"
        ></el-input>
        <el-input
          class="code"
          v-model="dateForm.code"
          placeholder="验证码"
          autocomplete="on"
          prefix-icon="el-icon-lock"
        ></el-input
        ><span
          v-show="sendAuthCode"
          class="auth_text auth_text_blue"
          @click="getAuthCode"
          >获取验证码</span
        >
        <span v-show="!sendAuthCode" class="auth_text">
          <span class="auth_text_blue">{{ auth_time }} </span>
          秒之重新发送验证码</span
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
var auth_timetimer;
import { getQueryObject, setCookie } from "@/utils/util";
import {
  SubmitLogin,
  GetVerifyCode,
  SecondValidateSendSMS,
  ValidateCode,
} from "@/network/api";
export default {
  data() {
    return {
      formLabelWidth: "80px",
      dialogVisible: false,
      codeimg: "",
      loading: false,
      img: "",
      checked: true,
      userForm: {
        username: "",
        password: "",
        // ServiceProviderType: "1",
        verifycode: "",
        backurl: "",
      },
      dateForm: {
        phone: "",
        code: "",
      },
      options: [
        {
          value: "0",
          label: "平台",
        },
        {
          value: "1",
          label: "采购商",
        },
        {
          value: "2",
          label: "供应商",
        },
      ],
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        verifycode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        ServiceProviderType: [
          { required: true, message: "请选择", trigger: "change" },
        ],
      },
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮’还是‘倒计时’ */,
      auth_time: 0 /*倒计时 计数器*/,
      title: "二次验证",
      codemsg: false,
    };
  },
  created() {
    // console.log(getQueryObject(window.top.location.href).backurl);
    // this.userForm.backurl = getQueryObject(window.top.location.href).backurl;
  },
  mounted() {
    // console.log(getQueryObject(top.location.href), "lllll", top.location);
    //  this.userForm.backurl =getQueryObject(top.location.href).backurl
    // this.userForm.ServiceProviderType =
    //   getQueryObject(top.location.href)?.sptype || "0";
    this.getcode();
    this.getCookies();
  },
  methods: {
    reference(){
      this.$store.commit("setlogin", this.userForm);
      this.$router.push('/forgetPwd')
    },
    getcode() {
      GetVerifyCode().then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.img = res.data;
        } else {
          this.$message({
            message: res.msg,
            center: true,
            offset: "40%",
            type: "warning",
            duration: 1000,
            onClose: () => {
              // this.$router.push("/forgetPwd"); //修改密码
              // this.loading = false;
            },
          });
        }
      });

      // console.log(getCookie("uuss"), "------", document.cookie);
    },
    submit() {
      console.log("isahdusag", this.loading);
      if (!this.loading) {
        this.submitForm("userForm");
      }
    },
    close() {
      this.dateForm = {
        phone: "",
        code: "",
      };
      this.auth_time = 0
      this.codemsg = false;
      this.dialogVisible = false;
      this.loading = false;
    },
    submitForm(formName) {
      // this.dialogFormVisible = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          this.loading = true;
          this.userForm.backurl = getQueryObject(top.location.href).backurl;
          // top.location.href = 'http://localhost:1111/#/';
          // this.$router.push("/ResetPwd" + window.location.search);
          console.log(this.userForm);
          if (this.checked == true) {
            console.log("checked == true");
          } else {
            console.log("清空cookie");
            // this.clearCookie();
          }
          SubmitLogin(this.userForm).then((res) => {
            // this.setCookies(this.userForm.username, this.userForm.password);
            if (res.code == 201) {
              this.$store.commit("setlogin", this.userForm);
              //请先修改初始密码
              this.$message({
                message: res.msg,
                center: true,
                offset: "40%",
                type: "warning",
                duration: 1000,
                onClose: () => {
                  this.$router.push("/ResetPwd"); //修改密码
                  this.loading = false;
                },
              });
            } else if (res.code == 202) {
              this.$store.commit("addtoken", res.data.token);
              if (res.data.mobilephone) {
                this.title = res.data.SecondValidateTypeDesc;
                this.dateForm.phone = res.data.mobilephone;
                this.codemsg = true;
              } else {
                this.codemsg = false;
                this.title = "绑定手机号";
              }
              setCookie("uutoken", res.data.token);
              if (this.checked == true) {
                this.setCookies(this.userForm.username, this.userForm.password);
              } else {
                this.clearCookies();
              }
              this.dialogVisible = true;
            } else if (res.code == 1) {
              setCookie("uutoken", res.msg);
              this.$store.commit("addtoken", res.msg);
              if (this.checked == true) {
                this.setCookies(this.userForm.username, this.userForm.password);
              } else {
                this.clearCookies();
              }
              this.$message({
                message: "登陆成功",
                center: true,
                type: "success",
                offset: "40%",
                duration: 1500,
                onClose: () => {
                  this.loading = false;
                  console.log("登陆成功");
                  top.location.href = res.data;
                },
              });
            } else {
              this.$message({
                message: res.msg,
                center: true,
                type: "error",
                offset: "40%",
                duration: 3000,
                onClose: () => {
                  this.getcode();
                  this.loading = false;
                },
              });
            }
          });
        } else {
          console.log("error submit!!");
          this.loading = false;
          return false;
        }
      });
    },
    save() {
      if (this.dateForm.phone.length < 11) {
        this.$message({
          showClose: true,
          message: "请输入11位手机号码",
          type: "error",
        });
        return;
      }
      if (!this.dateForm.code || !this.dateForm.code.length) {
        this.$message({
          showClose: true,
          message: "请输入验证码",
          type: "error",
        });
        return;
      }
      ValidateCode({ verifycode: this.dateForm.code }).then((res) => {
        
          this.$store.commit("setlogin", this.userForm);
          if (res.code == 1) {
            this.$message({
              message: "登陆成功",
              center: true,
              type: "success",
              offset: "40%",
              duration: 1500,
              onClose: () => {
                this.loading = false;
                this.dialogVisible = false;
                clearInterval(auth_timetimer);
                console.log("登陆成功");
                top.location.href = res.data;
              },
            });
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          }
      
      });
    },
    /**
     * @describe: 保存Cookies账号密码
     * @create: 2021/12/27
     **/
    setCookies(username, password, exDays = 60) {
      // Encrypt，加密账号密码
      var cipherUsername = this.CryptoJS.AES.encrypt(
        username + "",
        "secretKey"
      ).toString();
      var cipherPsw = this.CryptoJS.AES.encrypt(
        password + "",
        "secretKey"
      ).toString();
      let exDate = new Date(); // 获取时间
      exDate.setTime(exDate.getTime() + 24 * 60 * 60 * 1000 * exDays); // 保存的天数
      //字符串拼接cookie，为什么这里用了==，因为加密后的字符串也有个=号，
      // 影响下面getcookie的字符串切割，你也可以使用更炫酷的符号。
      // window.document.cookie = `currentUsername =${cipherUsername};path=/;expires = ${exDate.toUTCString()}`;
      // window.document.cookie = `password =${cipherPsw};path=/;expires = ${exDate.toUTCString()}`;
      //或者你可以这样写
      this.$cookies.set(
        "currentUsername",
        cipherUsername,
        exDate.toUTCString()
      );
      this.$cookies.set("password", cipherPsw, exDate.toUTCString());
    },
    /**
     * @describe: 读取cookies里面的账号密码
     * @create: 2021/12/27
     **/
    getCookies() {
      let cipherUsername = this.$cookies.get(`currentUsername`);
      let cipherPsw = this.$cookies.get(`password`);
      // 将用户名解码
      let decryptUsername = this.CryptoJS.AES.decrypt(
        cipherUsername,
        `secretKey`
      ).toString(this.CryptoJS.enc.Utf8);
      // 将密码解码
      let decryptPwd = this.CryptoJS.AES.decrypt(
        cipherPsw,
        `secretKey`
      ).toString(this.CryptoJS.enc.Utf8);
      // 将账号密码付给form
      // userForm: {
      // username: "",
      // password: "",
      this.userForm.username = decryptUsername;
      this.userForm.password = decryptPwd;
    },
    /**
     * @describe: 清空cookies
     * @create:2021/12/27
     **/
    clearCookies() {
      this.$cookies.remove(`currentUsername`);
      this.$cookies.remove(`password`);
    },
    getAuthCode() {
      if (this.dateForm.phone.length < 11) {
        this.$message({
          showClose: true,
          message: "请输入11位手机号码",
          type: "error",
        });
        return;
      }
      if (this.auth_time > 0) {
        return false;
      }
      this.auth_time = 60;
      SecondValidateSendSMS({ mobilephone: this.dateForm.phone }).then(
        (res) => {
          if (res.code == 1) {
            this.$message({
              message: this.dateForm.phone
                ? `短信已发送至${this.dateForm.phone}`
                : `短信已发送请注意接收`,
              center: true,
              type: "success",
              offset: "40%",
              duration: 1500,
              onClose: () => {
                this.sendAuthCode = false;
                this.auth_time = 60;
                auth_timetimer = setInterval(() => {
                  this.auth_time--;
                  if (this.auth_time <= 0) {
                    this.sendAuthCode = true;
                    clearInterval(auth_timetimer);
                  }
                }, 1000);
              },
            });
          } else {
            this.auth_time = 0
            this.$message({
              message: res.msg,
              center: true,
              type: "error",
              offset: "40%",
              duration: 3000,
            });
          }
        }
      );
    },
  },
};
</script>

<style scoped lang="less">
/deep/.el-form-item {
  margin-bottom: 10px;
}
/deep/.el-form {
  margin-top: 30px;
}
.bic {
  font-family: Helvetica, Tahoma, Arial;
  font-weight: bold;
  position: absolute;
  top: 0;
  width: 100%;
  line-height: 60px;
  text-align: center;
  height: 60px;
  background-color: #148be4;
  color: #fff;
}
.login_box2 {
  width: 100%;
  height: 100%;
  position: relative;
  // background-color: #fff;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
    background: rgba(255, 255, 255, 0.8);
}
.el-form {
  padding: 20px 30px;
  /* position: absolute; */
  /* bottom: 0; */
  width: 100%;
  box-sizing: border-box;
}
.el-button {
  width: 100%;
  height: 45px;
  font-size: 18px;
  line-height: 30px;
}
.code {
  width: 50%;
  float: left;
}
img {
  /* style="width: 100px; height: 30px; margin-left:5px;vertical-align: middle;" */
  display: line-inline;
  width: 45%;
  height: 40px;
  margin-left: 10px;
  vertical-align: middle;
  border-radius: 3px;
  margin-top: 10px;
}
.rememberMe {
  color: #333;
  float: left;
}
/deep/.el-input--mini .el-input__inner {
  height: 40px;
  font-size: 15px;
  line-height: 40px;
}
/deep/.el-input {
  margin-top: 10px;
}
/deep/.el-dialog__body {
  padding: 5px 10px;
  height: 100px;
}
/deep/.el-dialog__footer {
  padding: 5px 10px;
}
.auth_text {
  cursor: pointer;
  line-height: 60px;
  color: #999;
}
.auth_text_blue {
  color: blue;
}
</style>